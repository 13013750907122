function redirectRecurringPayment() {
  const stripe = Stripe(stripeAPIKey, {
    stripeAccount: connectAccountId,
  })

  stripe.redirectToCheckout({
    sessionId: sessionId
  }).then(function (result) {
    console.log(result.error.message)
  })
}

document.addEventListener('turbolinks:load', () => {
  if (typeof stripeAPIKey !== 'undefined') {
    redirectRecurringPayment();
  }
});
