function redirectOneOffPayment() {
  const stripe = Stripe(stripeAPIKey, {
    stripeAccount: connectAccountId,
  })

  stripe.redirectToCheckout({
    sessionId: sessionId
  }).then(function (result) {
    console.log(result.error.message)
  });
}

document.addEventListener('turbolinks:', () => {
  if (typeof stripeAPIKey !== 'undefined') {
    redirectOneOffPayment();
  }
});
